@import url("https://fonts.cdnfonts.com/css/sudo-var");
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@font-face {
  font-family: "ARCO";
  src: url("assets/fonts/ARCO.ttf");
}
@font-face {
  font-family: "Kicker Trial";
  src: url("assets/fonts/kickertrial.ttf");
}
@font-face {
  font-family: "SVN-ARCO Typography";
  src: url("assets/fonts/SVN-ARCOTypography.otf");
}
// Rest css
* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
html,
body {
  font-family: "ARCO", sans-serif;
  font-size: 16px;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}
// Style
.App {
  overflow: hidden;
  .snow-container {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    pointer-events: none;
  }

  .snowflake {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    opacity: 0.8;
    pointer-events: none;
  }

  .container {
    position: relative;
    margin: 0 auto;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .page-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .container {
      background: rgba(12, 3, 25, 0.6);
      padding: 20px 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      @media only screen and (max-width: 1180px) {
        flex-direction: column;
        gap: 18px;
      }
      .bg-section-1 {
        position: absolute;
      }
      .logo-app {
        width: 121px;
        flex-shrink: 0;
      }
      .nav-menu {
        ul {
          display: flex;
          align-items: center;
          gap: 30px;
          @media only screen and (max-width: 1280px) {
            gap: 25px;
          }
          @media only screen and (max-width: 886px) {
            // flex-direction: column;
            gap: 12px;
          }
          li {
            color: #eaee2a;
            text-align: center;
            font-family: ARCO;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 48.6px; /* 194.4% */
            @media only screen and (max-width: 886px) {
              line-height: 25px;
              font-size: 17px;
            }
            a {
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
      .socials {
        display: flex;
        align-items: center;
        gap: 18px;
        a {
          &:hover {
            opacity: 0.8;
          }
          width: 32px;
        }
      }
    }
  }
  .page-main {
    .section-intro {
      background: url("./assets/images/bg-section-1.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }
    .section-about {
      background: url("./assets/images/bg-section-2.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        .title {
          color: #ee2a2a;
          text-align: center;
          font-family: Kicker Trial;
          font-size: 80px;
          font-style: normal;
          font-weight: 900;
          line-height: 80px; /* 100% */
          text-transform: uppercase;
          @media only screen and (max-width: 568px) {
            font-size: 50px;
            line-height: 50px;
          }
        }
        .sub-title {
          padding-top: 36px;
          color: #cee929;
          text-align: center;
          text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.42);
          font-family: SVN-ARCO Typography;
          font-size: 51px;
          font-style: normal;
          font-weight: 400;
          line-height: 67.5px; /* 132.353% */
          span {
            color: #fff;
          }
          @media only screen and (max-width: 568px) {
            font-size: 30px;
            line-height: 30px;
            padding-top: 16px;
          }
        }
        .des {
          color: #fff;
          text-align: center;
          text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.42);

          /* content */
          font-family: Sudo Var;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 166.667% */
          letter-spacing: 0.9px;
          padding: 24px 0;
          max-width: 742px;
          @media only screen and (max-width: 480px) {
            line-height: 22px;
          }
        }
        .contact {
          color: #fff;
          text-align: center;
          text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.42);
          font-family: Staatliches;
          font-size: 31px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px; /* 122.581% */
          letter-spacing: 0.9px;
          width: 100%;
          word-wrap: break-word;

          @media only screen and (max-width: 568px) {
            font-size: 24px;
            line-height: 28px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 22px;
            line-height: 22px;
          }
        }
        .btn-actions {
          padding-top: 24px;
          display: flex;
          gap: 24px;
          a {
            padding: 18px 21px;
          }
          .btn-buy-now {
            &:hover {
              opacity: 0.8;
            }
            border-radius: 16px;
            border: 1px solid #fff;
            background: #fff;
            color: #f60000;
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 800;
            line-height: 12px; /* 100% */
            letter-spacing: 2px;
            text-transform: uppercase;
          }
          .btn-chart {
            &:hover {
              opacity: 0.8;
            }
            border-radius: 16px;
            border: 1px solid #fff;
            color: #fff;
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 800;
            line-height: 12px; /* 100% */
            letter-spacing: 2px;
            text-transform: uppercase;
          }
        }
      }
    }
    .section-how-to-buy {
      background: url("./assets/images/bg-section-3.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      .container {
        padding-top: 99px;
        @media only screen and (max-width: 568px) {
          padding-top: 30px;
        }
        .title {
          color: #ee2a2a;
          text-align: center;
          font-family: Kicker Trial;
          font-size: 80px;
          font-style: normal;
          font-weight: 900;
          line-height: 80px; /* 100% */
          text-transform: uppercase;
          @media only screen and (max-width: 568px) {
            font-size: 50px;
            line-height: 50px;
          }
        }
        .list-content {
          display: flex;
          flex-direction: column;
          gap: 32px;
          padding-top: 36px;
          align-items: center;
          @media only screen and (max-width: 568px) {
            padding-top: 18px;
            gap: 12px;
          }
          .content {
            h3 {
              color: #cee929;
              text-align: center;
              text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.42);
              font-family: ARCO;
              font-size: 30px;
              font-style: normal;
              font-weight: 700;
              line-height: 37px; /* 123.333% */
              @media only screen and (max-width: 568px) {
                font-size: 26px;
                line-height: 30px;
              }
            }
            p {
              padding-top: 8px;
              color: #e6dddd;
              text-align: center;
              text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.42);
              max-width: 968px;
              margin: 0 auto;
              /* content */
              font-family: Sudo Var;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px; /* 166.667% */
              letter-spacing: 0.9px;
              @media only screen and (max-width: 480px) {
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .section-tokenomics {
      background: url("./assets/images/bg-section-4.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 108px 0;
        @media only screen and (max-width: 768px) {
          padding: 50px 0;
        }
        @media only screen and (max-width: 568px) {
          padding: 30px 0;
        }
        .title {
          color: #fff;
          text-align: center;
          font-family: Kicker Trial;
          font-size: 80px;
          font-style: normal;
          font-weight: 900;
          line-height: 80px; /* 100% */
          text-transform: uppercase;
          @media only screen and (max-width: 568px) {
            font-size: 50px;
            line-height: 50px;
          }
        }
        .list-tokenomics {
          display: flex;
          align-items: center;
          padding-top: 120px;
          gap: 28px;
          @media only screen and (max-width: 1180px) {
            flex-wrap: wrap;
            justify-content: center;
          }
          @media only screen and (max-width: 768px) {
            padding-top: 60px;
          }
          @media only screen and (max-width: 480px) {
            padding-top: 30px;
            gap: 12px;
          }
          .item {
            width: 252px;
            position: relative;
            .content {
              position: absolute;
              top: 70%;
              left: 50%;
              transform: translate(-50%, -50%);
              transform-origin: 0 0;
              width: 100%;
              h3 {
                color: #fac714;
                text-align: center;
                font-family: ARCO;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px; /* 100% */
              }
              p {
                padding-top: 51px;
                color: #280147;
                text-align: center;
                font-family: ARCO;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px; /* 166.667% */
                letter-spacing: 0.9px;
              }
            }
          }
        }
      }
    }
  }
  .page-footer {
    background: linear-gradient(90deg, #c8e881 0%, #4252e6 100%);
    .container {
      align-items: center;
      justify-content: space-evenly;
      display: flex;
      padding: 8px 0;
      @media only screen and (max-width: 668px) {
        flex-direction: column;
        gap: 12px;
      }
      .logo-footer {
        width: 139px;
        flex-shrink: 0;
      }
      p {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
      .socials {
        display: flex;
        gap: 24px;
        align-items: center;
        a {
          &:hover {
            opacity: 0.8;
          }
          img {
            width: 32px;
          }
        }
      }
    }
  }
}
@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    transform: translateY(100vh);
  }
}

@keyframes diagonal-fall {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
    transform: translate(10vw, 100vh);
  }
}
